import { ApiResponse, DeleteFeedbackRequest, Feedback, FeedbackResponse, InsertFeedbackRequest, InsertFeedbackResponse, UpdateFeedbackRequest, UpdateFeedbackResponse } from "ssp-contracts";
import { FEEDBACK_URL } from "../../constants"
import { httpClient } from "../../services/httpClient"
import { Blob } from "buffer";


/** Feedback */
export const getAllFeedbacks = () => {
    return httpClient.get<ApiResponse<FeedbackResponse[]>>(FEEDBACK_URL);
}

export const getFeedbackById = (id: string) => {
    return httpClient.get<ApiResponse<Feedback[]>>(FEEDBACK_URL, {params: {id: id}});
}

export const createFeedback = (payload: InsertFeedbackRequest) => {
    const formData = new FormData();
    formData.append("title", payload.title);
    formData.append("description", payload.description);
    formData.append("uploadFile", payload.uploadFile);
    formData.append("path", payload.path);
    return httpClient.post<ApiResponse<InsertFeedbackResponse>>(FEEDBACK_URL, formData, {headers: {'Content-Type': 'multipart/form-data'}});
}

export const modifyFeedback = (payload: UpdateFeedbackRequest) => {
    const formDta = new FormData();
    formDta.append("id", payload.id);
    formDta.append("title", payload.title);
    formDta.append("description", payload.description);
    formDta.append("oldFileName", payload.oldFileName);
    formDta.append("newUploadFile", payload.newUploadFile);
    formDta.append("path", payload.path);

    return httpClient.put<ApiResponse<UpdateFeedbackResponse>>(FEEDBACK_URL, formDta, {headers: {'Content-Type': 'multipart/form-data'}});
}

export const getFeedbackImage = (fileName: string) => {
    return httpClient.get<Blob>(`${FEEDBACK_URL}/file`, {params: {fileName: fileName }, responseType: 'blob'});
}

export const removeFeedback = (payload: DeleteFeedbackRequest) => {
    return httpClient.delete<ApiResponse<void>>(`${FEEDBACK_URL}`, {params: {id: payload.id, fileName: payload.fileName}});
}

/** Upload File */
// export const uploadFeedbackFile = (file: FormData) => {
//     return httpClient.post(UPLOAD_FILE_URL, file, {headers: {'Content-Type': 'multipart/form-data'}});
// }

// export const uploadUpdateFeedback = (file: FormData) => {
//     return httpClient.put(UPLOAD_FILE_URL, file, {headers: {'Content-Type': 'multipart/form-data'}});
// }



// export const removeFeedbackImage = (payload: DeleteFeedbackRequest) => {
//     return httpClient.delete(`${UPLOAD_FILE_URL}`, {params: payload});
// }