import { FormProvider, useForm } from "react-hook-form"
import { GrFormView } from "react-icons/gr"
import { RiDeleteBin5Line } from "react-icons/ri"
import { TbEdit } from "react-icons/tb"
import { deleteQuote, fetchAllQuotes } from "../../store"
import { useState } from "react"
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../store/store"
import CruQuote from "./CruQuote"
import useConfirm from "../../hooks/useConfirm";
import { ToastType } from "../../constants/appConstants"
import createToast from "../../hooks/createToast"
import { DeleteQuoteRequest, QuoteForm, QuoteResponse } from "ssp-contracts"
import QuotePreview from "./QuotePreview"


const Quotes = () => {

    const defaultValuesForQuoteForm: QuoteForm = {
        description: ''
    }

    const methods = useForm<QuoteForm>({
        defaultValues: defaultValuesForQuoteForm
    });
    const quotes = useSelector((state: RootState) => state.quotes.quotesData);
    const dispatch = useAppDispatch();
    const [ConfirmationDialog, confirm] = useConfirm();
    const [searchQuery, setSearchQuery] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const quotesPerPage = 5;
    const filteredQuotes = quotes?.filter((quote) =>
        quote?.description?.toLowerCase().includes(searchQuery?.toLowerCase())
    );
    const indexOfLastQuote = currentPage * quotesPerPage;
    const indexOfFirstQuote = indexOfLastQuote - quotesPerPage;
    const currentQuotes = filteredQuotes.slice(indexOfFirstQuote, indexOfLastQuote);

    const [selectedQuote, setSelectedQuote] = useState<QuoteResponse>({
        id: '',
        description: '',
    });

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const handleEdit = async (quote: QuoteResponse) => {
        const isConfirm = await confirm('Edit Quote', 'Are you sure want to Edit?', 'No', 'Yes');
        if (isConfirm) {
            setShowModal(true);
            setSelectedQuote(quote);
            setIsEditMode(true);
        } else {
            setIsEditMode(false);
        }
    }
    const handleView = async (quote: QuoteResponse) => {
        setShowPreview(true);
        setSelectedQuote(quote);
    }

    const handleDelete = async (quote: QuoteResponse) => {
        const isConfirm = await confirm('Delete Quote', 'Are you sure want to Delete?', 'Cancel', 'Confirm', ToastType.Error);
        if (isConfirm) {
            const deleteQuoteData: DeleteQuoteRequest = {
                id: quote.id,
            }
            dispatch(deleteQuote(deleteQuoteData)).then((quoteResponse) => {
                if (quoteResponse.meta.requestStatus === 'fulfilled') {
                    dispatch(fetchAllQuotes());
                    createToast('Quote Deleted', 'Quote deleted successfully', ToastType.Success);
                } else {
                    createToast('Quote Deletion Error', 'Some Error Occured While deleting Quote ', ToastType.Error);
                }
            })
        }

    }

    return (
        <FormProvider {...methods}>
            <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
                {/* <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                    Feedbacks
                </h4> */}
                <div className="flex justify-between items-center mb-2 w-full">
                    <input
                        type="text"
                        placeholder="Search by title or description..."
                        className="mb-4 p-2 border rounded"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button className="px-2 py-2 border rounded bg-primary text-white" onClick={() => setShowModal(true)}>Add Quote</button>
                </div>
                <div className="flex flex-col">
                    <div className="grid grid-cols-2 rounded-sm bg-gray-2 dark:bg-meta-2 sm:grid-cols-2">

                        <div className="p-2.5 text-left xl:p-5">
                            <h5 className="text-sm font-medium uppercase xsm:text-base">
                                Description
                            </h5>
                        </div>
                        <div className="hidden p-2.5 sm:block xl:p-5 text-right">
                            <h5 className="text-sm font-medium uppercase xsm:text-base">
                                Action
                            </h5>
                        </div>
                    </div>

                    {currentQuotes.map((quote, key) => (
                        <div
                            className={`grid grid-cols-2 sm:grid-cols-2 ${key === quotes.length - 1
                                ? ''
                                : 'border-b border-stroke dark:border-strokedark'
                                }`}
                            key={key}
                        >

                            <div className="flex items-center justify-start p-2.5 xl:p-5 ">
                                <p className="text-meta-3">{quote.description}</p>
                            </div>

                            <div className="hidden items-center justify-end p-2.5 sm:flex xl:p-5">
                                <div className="text-black dark:text-white flex justify-center items-center grid-4">
                                    <GrFormView className="cursor-pointer text-2xl mr-1" onClick={() => handleView(quote)} />
                                    <TbEdit className="cursor-pointer text-2xl mr-1" onClick={() => handleEdit(quote)} />
                                    <RiDeleteBin5Line className="cursor-pointer text-2xl" onClick={() => handleDelete(quote)} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex justify-center mt-4">
                    {Array.from({ length: Math.ceil(filteredQuotes.length / quotesPerPage) }, (_, i) => (
                        <button
                            key={i}
                            className={`mx-1 py-2 px-3 border rounded ${currentPage === i + 1 ? 'bg-primary text-white' : ''}`}
                            onClick={() => paginate(i + 1)}
                        >
                            {i + 1}
                        </button>
                    ))}
                </div>
            </div>
            <CruQuote open={showModal} setOpen={setShowModal} selectedQuote={selectedQuote} isEditQuote={isEditMode} setIsEditQuote={setIsEditMode} />
            <QuotePreview open={showPreview} setOpen={setShowPreview} selectedQuote={selectedQuote} />

            <ConfirmationDialog />

        </FormProvider>
    )
}

export default Quotes