import { createSlice } from "@reduxjs/toolkit";
import { feedbackInitialState } from "./feedback.initialState";
import { fetchAllFeedback, getFeedbackDocument } from "./feedback.actions";

const feedbackSlice = createSlice({
    name: "feedback",
    initialState: feedbackInitialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchAllFeedback.fulfilled, (state, action) => {
            state.feedbackData = action.payload.data!;
        });
        builder.addCase(fetchAllFeedback.pending, (state) => {
            state.feedbackData = [];
        });
        builder.addCase(fetchAllFeedback.rejected, (state) => {
            state.feedbackData = [];
        });
        builder.addCase(getFeedbackDocument.fulfilled, (state, action) => {
            state.feedbackImage = action.payload as Blob;
            state.isUploadFeedbackReady = true;
        });
        builder.addCase(getFeedbackDocument.pending, (state) => {
            state.feedbackImage = undefined;
            state.isUploadFeedbackReady = false;
        });
        builder.addCase(getFeedbackDocument.rejected, (state) => {
            state.feedbackImage = undefined;
            state.isUploadFeedbackReady = false;
        });
    }
})

export const {} = feedbackSlice.actions;

export const { reducer: feedbackReducer } = feedbackSlice;