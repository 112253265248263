import { createSlice } from "@reduxjs/toolkit";
import { quoteInitialState } from "./quote.initialState";
import { fetchAllQuotes } from "./quote.actions";


const quoteSlice = createSlice({
    name: "quote",
    initialState: quoteInitialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchAllQuotes.fulfilled, (state, action) => {
            state.quotesData = action.payload.data!;
        });
        builder.addCase(fetchAllQuotes.pending, (state) => {
            state.quotesData = [];
        });
        builder.addCase(fetchAllQuotes.rejected, (state) => {
            state.quotesData = [];
        });
    }
})

export const {} = quoteSlice.actions;

export const { reducer: quoteReducer } = quoteSlice;