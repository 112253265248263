import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FeedbackResponse } from "ssp-contracts";
import { getFullUrl } from "../../../utilities/commonLibFunc";

interface FeedbackPreviewProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    selectedFeedback: FeedbackResponse;
}
const FeedbackPreview = ({ open, setOpen, selectedFeedback }: FeedbackPreviewProps) => {

    const onClose = () => {
        setOpen(false);
    }
    return (
        <Dialog open={open} onClose={onClose} className="relative z-999">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-4xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                            <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark flex justify-between ">
                                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                    {'Feedback'}
                                </DialogTitle>
                                <IoMdCloseCircleOutline onClick={onClose} className="text-2xl cursor-pointer text-slate-500 hover:text-slate-700" />
                            </div>

                            <div className="p-6.5">
                                <div className="mb-4.5 flex flex-col justify-center items-center gap-6 py-10">
                                    <div>
                                        <img src={getFullUrl(selectedFeedback.images?.thumbnail)} alt="Uploaded Preview" className="mx-auto h-1/2 object-cover" />
                                    </div>
                                    <div className="text-2xl font-bold">
                                        {selectedFeedback.title}
                                    </div>
                                    <div>
                                        {selectedFeedback.description}
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={onClose}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    {'Close'}
                                </button>
                            </div>
                        </div>


                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

export default FeedbackPreview