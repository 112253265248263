import { useSelector } from "react-redux"
import { CardDataStats } from "../../components"
import { RootState } from "../../store/store";
import { FeedbackResponse, QuoteResponse } from "ssp-contracts";
import { VscFeedback } from "react-icons/vsc";
import { TbBlockquote } from "react-icons/tb";


const Dashboard = () => {
  const feedbacks: FeedbackResponse[] = useSelector((state: RootState) => state.feedback.feedbackData);
  const quotes: QuoteResponse[] = useSelector((state: RootState) => state.quotes.quotesData);

  return (
    <>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        <CardDataStats title="Total Feedback" total={feedbacks.length.toString()} rate="0" levelUp>
          <VscFeedback />
        </CardDataStats>
        <CardDataStats title="Total Quotes" total={quotes.length.toString()} rate="0%" levelUp>
          <TbBlockquote />
        </CardDataStats>

      </div>
      {/* <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
        <ChartOne />
        <ChartTwo />
        <ChartThree />
        {/* <MapOne /> *}
        <div className="col-span-12 xl:col-span-8">
          <TableOne />
        </div>
        <ChatCard />
      </div> */}
    </>
  )
}

export default Dashboard