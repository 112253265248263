
interface LoaderProps {
    text?: string;
}
const AppLoader = ({ text = 'Loading...' }: LoaderProps) => {

    return (
        <div className="flex flex-col items-center justify-center space-y-4">
            {/* Spinner */}
            <div className="w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
            {/* Optional Loading Text */}
            {text && <p className="text-lg font-medium text-gray-700">{text}</p>}
        </div>
    )
}

export default AppLoader