import { createContext, useContext, useEffect, useState } from "react";
import { useAppDispatch } from "../store/store";
import { fetchAllFeedback, fetchAllQuotes, fetchLoginUser, setUserLogin } from "../store";
import { isTokenExpired } from "../utilities/tokenUtils";
import { ApiResponse, LoginForm, LoginRequest, LoginResponse } from "ssp-contracts";

interface AuthContextType {
    isAuthenticated: boolean;
    loginWithCredentials: (data: LoginForm) => Promise<boolean>;
    loginWithGoogle: () => void;
    logout: () => void;
    loading: boolean;
}
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
}

interface AuthProviderProps {
    children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const dispatch = useAppDispatch();

    const loginWithCredentials = async (data: LoginForm) => {
        setLoading(true);
        try {
            const loginRequest: LoginRequest = {
                email: data.email,
                password: data.password
            };
            const response = await dispatch(setUserLogin(loginRequest));
            if (response.meta.requestStatus === 'fulfilled') {
                const token = (response.payload as ApiResponse<LoginResponse>).data?.token;

                if (token) {
                    setIsAuthenticated(true);
                    localStorage.setItem('authToken', btoa(token));
                    if (data.isRemembered) {
                        localStorage.setItem('user', btoa(JSON.stringify(data.email)));
                    }
                    await dispatch(fetchLoginUser());
                    return true;
                }
            }
            setIsAuthenticated(false);
            return false;
        } catch (error) {
            setIsAuthenticated(false);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const loginWithGoogle = () => {
        setIsAuthenticated(true);
        localStorage.setItem('user', JSON.stringify({ email: 'googleUser@example.com' }));
    };

    const logout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem('user');
        localStorage.removeItem('authToken');
    };

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchAllFeedback());
            dispatch(fetchAllQuotes());
        }
    }, [isAuthenticated, dispatch]);

    useEffect(() => {
        const storedToken = localStorage.getItem('authToken');
        if (storedToken) {
            const token = atob(storedToken);
            if (!isTokenExpired(token)) {
                setIsAuthenticated(true);

            }

        }
        setLoading(false);
    }, []);

    const value = {
        isAuthenticated,
        loginWithCredentials,
        loginWithGoogle,
        logout,
        loading
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

