import { createAsyncThunk } from "@reduxjs/toolkit";
import * as authenticationApi from './authentication.api';
import { ApiResponse, LoginRequest, LoginResponse, RegisterationRequest, RegistrationResponse, UserResponse } from "ssp-contracts";
import { AxiosError } from "axios";

export const fetchAllUsers = createAsyncThunk<UserResponse[], void>('authentication/fetchAllUsers', async (_, thunkAPI) => {
    try {
        const response = await authenticationApi.getAllUsers();
    
        if (!response.data?.data) {
            return [];
        }
        const userDetails: UserResponse[] = response.data.data.map(user => ({
            id: user.id,
            name: user.name,
            email: user.email
        }))
        
        return userDetails;
    } catch (error) {
        return thunkAPI.rejectWithValue((error as AxiosError).response?.data || 'Failed to fetch Users');
    }
});

export const setUserLogin = createAsyncThunk<ApiResponse<LoginResponse>, LoginRequest>('authentication/setUserLogin', async (payload: LoginRequest, thunkAPI) => {
    try {
        const response = await authenticationApi.userLogin(payload);

        if (response.data.success) {
            return response.data;
        } else {
            return thunkAPI.rejectWithValue(response.data.error || 'Login Failed');
        }
        
    } catch (error) {
        return thunkAPI.rejectWithValue((error as AxiosError).response?.data || 'Login Failed');
    }
});
export const setUserRegistration = createAsyncThunk<RegistrationResponse, RegisterationRequest>('authentication/fetchUserLogin', async (payload: RegisterationRequest, thunkAPI) => {
    try {
        const response = await authenticationApi.userRegister(payload);
        if (response.data.success) {
            return response.data.data as RegistrationResponse;
        } else {
            return thunkAPI.rejectWithValue(response.data.error || 'Registration Failed');
        }
        
    } catch (error) {
        return thunkAPI.rejectWithValue((error as AxiosError).response?.data || 'Registration Failed');
    }
});
export const fetchLoginUser = createAsyncThunk<UserResponse, void>('authentication/fetchLoginUser', async (_, thunkAPI) => {
    try {
        const response = await authenticationApi.getLoginUser();
        const responseData = (response.data as ApiResponse<UserResponse>).data;
        if (!responseData) {
            throw new Error('User data is missing');
        }
        const userDetails: UserResponse = {
            id: responseData.id,
            name: responseData.name,
            email: responseData.email,
            }
        return userDetails;
        
    } catch (error) {
        return thunkAPI.rejectWithValue((error as AxiosError).response?.data || 'Failed to fetch user data')
    }
});

