import { Dialog, DialogBackdrop, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react"
import { Controller, useFormContext } from "react-hook-form";
import { ToastType } from "../../../constants/appConstants";
import createToast from "../../../hooks/createToast";
import { createQuote, fetchAllQuotes, updateQuote } from "../../../store";
import { useAppDispatch } from "../../../store/store";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { Fragment, useEffect, useState } from "react";
import { InsertQuoteRequest, QuoteForm, QuoteResponse, UpdateQuoteRequest } from "ssp-contracts";
import ErrorMessage from "../../../components/ErrorMessage";
import { areObjectsEqualByKeys } from "../../../utilities/commonLibFunc";
import { AppLoader } from "../../../components";


interface CruQuoteProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    selectedQuote: QuoteResponse;
    isEditQuote: boolean;
    setIsEditQuote: (value: boolean) => void;

}
const CruQuote = ({ open, setOpen, selectedQuote, isEditQuote, setIsEditQuote }: CruQuoteProps) => {
    const { control, handleSubmit, formState: { errors }, setValue, reset } = useFormContext();
    const [loading, setLoading] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const quoteProperties: (keyof QuoteForm)[] = [
        "description",
    ]

    const isNewAndPreviousDataSame = (data: UpdateQuoteRequest, previousData: QuoteResponse) => {
        return areObjectsEqualByKeys(data, previousData, quoteProperties);
    }

    const onSubmit = handleSubmit((data) => {
        setLoading(true);
        if (isEditQuote) {
            const quoteData: UpdateQuoteRequest = {
                description: data.description,
                id: selectedQuote.id
            }
            if (!isNewAndPreviousDataSame(quoteData, selectedQuote)) {
                dispatch(updateQuote(quoteData)).then((quoteResponse) => {
                    if (quoteResponse.meta.requestStatus === 'fulfilled') {
                        dispatch(fetchAllQuotes());
                        createToast('Quote Update', 'Quote updated successfully', ToastType.Success);
                        onClose()
                    } else {
                        createToast('Error updating Quote', 'Error in updating Quote', ToastType.Error);
                        return;
                    }
                }).catch(() => {
                    createToast('Error updating Quote', 'Error in updating Quote', ToastType.Error);
                    return;
                }).finally(() => {
                    setLoading(false);
                })
            } else {
                setLoading(false);
                onClose();
            }
        } else {
            const quoteData: InsertQuoteRequest = {
                description: data.description,
            }
            dispatch(createQuote(quoteData)).then((quoteResponse) => {
                if (quoteResponse.meta.requestStatus === 'fulfilled') {
                    dispatch(fetchAllQuotes());
                    createToast('Quote Saved', 'Quote submitted successfully', ToastType.Success);
                    setOpen(false);
                } else {
                    createToast('Error creating Quote', 'Error creating Quote', ToastType.Error);
                }
            }).catch(() => {
                createToast('Error creating Quote', 'Error creating Quote', ToastType.Error);
                return;
            }).finally(() => {
                setLoading(false);
            });
        }

    });

    const onClose = () => {
        if (!loading) {
            setIsEditQuote(false);
            reset();
            setOpen(false);
        }
    }

    useEffect(() => {
        if (isEditQuote) {
            // Set form values when editing or viewing
            setValue('description', selectedQuote.description || '');
        } else {
            // Reset form for new quote
            reset({
                description: '',
            });
        }
    }, [selectedQuote, isEditQuote, setValue, reset]);



    return (
        <Transition show={open} as={Fragment}>
            <Dialog as="div" onClose={() => { }} static className="relative z-999">
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >

                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                    />

                </TransitionChild>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        {/* Overlay Loader */}
                        {loading && (
                            <div className="absolute inset-0 z-20 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                <AppLoader text="Quote Saving... Please wait." /> {/* Loader with Overlay */}
                            </div>
                        )}
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel
                                transition
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-4xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                            >
                                <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                                    <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark flex justify-between ">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            {isEditQuote ? 'Update Quote' : 'Add Quote'}
                                        </DialogTitle>
                                        <IoMdCloseCircleOutline onClick={onClose} className="text-2xl cursor-pointer text-slate-500 hover:text-slate-700" />
                                    </div>
                                    <form onSubmit={(e) => { e.preventDefault(); onSubmit(e); }}>
                                        <div className="p-6.5">
                                            <div className="mb-4.5 flex flex-col gap-6">

                                                <div className="w-full">
                                                    <label className="mb-2.5 block text-black dark:text-white">
                                                        Description
                                                    </label>
                                                    <Controller
                                                        name="description"
                                                        control={control}
                                                        defaultValue={''}
                                                        rules={{
                                                            required: 'Description is Required',
                                                        }}
                                                        render={({ field }) => <textarea
                                                            rows={6}
                                                            {...field}
                                                            placeholder="Enter Description"
                                                            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                                                        ></textarea>}
                                                    />
                                                    <ErrorMessage errors={errors} name="description" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">

                                            <button
                                                type="submit"
                                                className="inline-flex w-full justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                                            >
                                                {isEditQuote ? 'Update Quote' : 'Add Quote'}
                                            </button>

                                            <button
                                                type="button"
                                                data-autofocus
                                                onClick={onClose}
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            >
                                                {'Cancel'}
                                            </button>
                                        </div>
                                    </form>
                                </div>


                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default CruQuote