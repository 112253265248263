import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { dashboardReducer } from "./dashboard";
import { feedbackReducer } from "./feedback";
import { quoteReducer } from "./quote";
import { authenticationReducer } from "./authentication";


export const store = configureStore({
    reducer: {
        dashboard: dashboardReducer,
        feedback: feedbackReducer,
        quotes: quoteReducer,
        authentication: authenticationReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export type AppDispatch = typeof store.dispatch;