import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AppContext"

const PrivateRoute = () => {
    const { isAuthenticated, loading } = useAuth();

    if (loading) {
        return <div>Loading...</div>;
    }
    return isAuthenticated ? <Outlet /> : <Navigate to='/login' />;
}

export default PrivateRoute