import { ApiResponse, LoginRequest, LoginResponse, RegisterationRequest, RegistrationResponse, UserResponse } from "ssp-contracts";
import { httpClient } from "../../services/httpClient"
import { USER_URL } from "../../constants";

export const getAllUsers = () => {
    return httpClient.get<ApiResponse<UserResponse[]>>(USER_URL);
}

export const userLogin = (payload: LoginRequest) => {
    return httpClient.post<ApiResponse<LoginResponse>>(`${USER_URL}/login`, payload);
}

export const userRegister = (payload: RegisterationRequest) => {
    return httpClient.post<ApiResponse<RegistrationResponse>>(`${USER_URL}/register`, payload);
}

export const getLoginUser = () => {
    return httpClient.get<ApiResponse<UserResponse>>(`${USER_URL}`);
}


