import { Feedback } from "ssp-contracts";

export function areObjectsEqualByKeys<T>(newData: T, previousData: T, keysToCompare: (keyof T)[]) {
    return keysToCompare.every((key) => {
        const newValue = newData[key];

        // Check if the key is 'uploadImage' and handle the special case for image comparison
    if (key === 'uploadImage') {
        if (newValue instanceof FileList && newValue.length > 0 && (previousData as Feedback).imageVersions?.preview) {
            // Handle when the previousData has 'imageUrl' instead of 'uploadImage'
            const previousImageUrl = (previousData as Feedback).imageVersions?.preview || ''; // Default to empty string if not present
            const newFileName = newValue[0]?.name;
            const previousFileName = previousImageUrl.split('/').pop() || previousImageUrl;
            return newFileName === previousFileName;
        }
    }

        let previousValue = previousData[key];

        // if (key === 'uploadImage') {
        //     previousValue = previousData['imageUrl'];
        //     if (Array.isArray(newValue) && Array.isArray(previousValue)) {
        //         const newFileName = newValue[0].name;
        //         const previousFileName = previousValue[0].imageUrl.split('/').pop() || previousValue[0].imageUrl;
        //         return newFileName === previousFileName;
        //     }
        // }
        if (typeof newValue === 'object' && typeof previousData === 'object') {
            return JSON.stringify(newValue) === JSON.stringify(previousValue);
        }

        return newValue === previousValue;
    })
}

 export function getFullUrl(fileName: string) {
        const region = import.meta.env.VITE_WASABI_REGION;
        const bucketName = import.meta.env.VITE_WASABI_BUCKET_NAME_FOR_FEEDBACK;
        return `https://s3.${region}.wasabisys.com/${bucketName}/${fileName}`;
    };
