// import { LoginForm } from "../store";

const redirectUrlKey = "CALLBACK_KEY";
const userAuthUrl = "/api/Base";

enum ToastType {
    Success = 0,
    Warning = 1,
    Error = 2,
}

// const allowedUsers: LoginForm[] = [
//     {
//     "email": "rajjak.sayed.slc@gmail.com",
//     "password": "rajjak",
//     "rememberMe": false
    
// },
// {
//     "email": "camgsp79@gmail.com",
//     "password": "30112010",
//     'rememberMe': false
// },
// {
//     "email": "shamasayed1805@gmail.com",
//     "password": "shamasayed",
//     'rememberMe': false
// }
// ]

export { redirectUrlKey, userAuthUrl, ToastType };
