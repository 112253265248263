import { createAsyncThunk } from "@reduxjs/toolkit";
import * as feedbackApi from './feedback.api';
import { ApiResponse, DeleteFeedbackRequest, FeedbackResponse, InsertFeedbackRequest, InsertFeedbackResponse, UpdateFeedbackRequest, UpdateFeedbackResponse } from "ssp-contracts";
import { AxiosError } from "axios";
import { Blob } from "buffer";

export const fetchAllFeedback = createAsyncThunk<ApiResponse<FeedbackResponse[]>>('feedback/fetchAllFeedback', async (_, thunkAPI) => {
    try {
        const response = await feedbackApi.getAllFeedbacks();
        if (response.data.success) {
            return response.data;
        } else {
            return thunkAPI.rejectWithValue(response.data.error || 'Failed to fetch feedback');
        }
    } catch (error) {
        return thunkAPI.rejectWithValue((error as AxiosError).message || 'Failed to fetch feedback');     
    }
});

export const insertFeedback = createAsyncThunk<ApiResponse<InsertFeedbackResponse>, InsertFeedbackRequest>('feedback/insertFeedback', async (payload: InsertFeedbackRequest, thunkAPI)=> {
    try {
        const response = await feedbackApi.createFeedback(payload);
        if (response.data.success) {
            return response.data;
        } else {
            return thunkAPI.rejectWithValue(response.data.error || 'Failed to fetch feedback');
        }
    } catch(error) {
        return thunkAPI.rejectWithValue((error as AxiosError).message || 'Failed to fetch feedback');

    }
});
export const updateFeedback = createAsyncThunk<ApiResponse<UpdateFeedbackResponse>, UpdateFeedbackRequest>('feedback/updateFeedback', async (payload: UpdateFeedbackRequest, thunkAPI)=> {
    try {
        const response = await feedbackApi.modifyFeedback(payload);
        if (response.data.success) {
            return response.data;
        } else {
            return thunkAPI.rejectWithValue(response.data.error || 'Failed to fetch feedback');
        }
        
    } catch (error) {
        return thunkAPI.rejectWithValue((error as AxiosError).message || 'Failed to fetch feedback');
    }
});
export const deleteFeedback = createAsyncThunk<ApiResponse<void>, DeleteFeedbackRequest>('feedback/deleteFeedback', async (payload: DeleteFeedbackRequest, thunkAPI)=> {
    try {
        const response = await feedbackApi.removeFeedback(payload);
        if (response.status === 200 && response.data.success) {
            return response.data;
        } else {
            return thunkAPI.rejectWithValue(response.data.error || 'Failed to fetch feedback');
        }
        
    } catch (error) {
        return thunkAPI.rejectWithValue((error as AxiosError).message || 'Failed to fetch feedback');
    }
});

export const getFeedbackDocument = createAsyncThunk<Blob, string>('feedback/getFeedbackDocument', async (filePath: string, thunkAPI) => {
        try {
            const response = await feedbackApi.getFeedbackImage(filePath);
            return response.data;
            
        } catch (error) {
            console.error('Error in getFeedbackDocument:', error);
            return thunkAPI.rejectWithValue((error as AxiosError).message || 'Failed to fetch feedback Image');
        }
})

// export const uploadFeedbackDocumentForFilePath = createAsyncThunk('feedback/uploadFeedbackDocumentForFilePath', async (file: FormData, {rejectWithValue}) => {
//     try {
//         const response = await feedbackApi.uploadFeedbackFile(file);
//         console.log('upload feedback document', response);
//         return response.data;
//     } catch (error) {
//             console.error('Error in uploadFeedbackDocumentForFilePath:', error);
//             return rejectWithValue(error);
//     }
// })

// export const uploadUpdateFeedbackDocument = createAsyncThunk(
//     'feedback/uploadUpdateFeedbackDocument', 
//     async (file: FormData, {rejectWithValue}) => {
//         try {

//     const response = await feedbackApi.uploadUpdateFeedback(file);
//     console.log('upload updated feedback document', response);
//     return response.data;
//         } catch (error) {
//             console.error('Error in uploadUpdateFeedbackDocument:', error);
//             return rejectWithValue(error);
//         }
// })



// export const deleteFeedbackDocument = createAsyncThunk('feedback/deleteFeedbackDocument', async (payload: DeleteFeedbackRequest, {rejectWithValue}) => {
//         try {
//             const response = await feedbackApi.removeFeedbackImage(payload);
//             return response.data;
//         } catch (error) {
//             console.error('Error in getFeedbackDocument:', error);
//             if (error instanceof AxiosError) {
//                 // Extract only the serializable parts of the AxiosError
//                 return rejectWithValue({
//                 message: error.message,
//                 code: error.code,
//                 status: error.response?.status,
//                 data: error.response?.data,
//                 });
//             }
//             return rejectWithValue(error);
//         }
// })

