import { ApiResponse, DeleteQuoteRequest, InsertQuoteRequest, InsertQuoteResponse, QuoteResponse, UpdateQuoteRequest, UpdateQuoteResponse } from "ssp-contracts";
import { QUOTES_URL } from "../../constants"
import { httpClient } from "../../services/httpClient"

export const getAllQuotes = () => {
    return httpClient.get<ApiResponse<QuoteResponse[]>>(QUOTES_URL);
}

export const insertQuote = (payload: InsertQuoteRequest) => {
    return httpClient.post<ApiResponse<InsertQuoteResponse>>(QUOTES_URL, payload);
}

export const removeQuote = (payload: DeleteQuoteRequest) => {
    return httpClient.delete<ApiResponse<void>>(`${QUOTES_URL}/${payload.id}`)
}

export const modifyQuote = (payload: UpdateQuoteRequest) => {
    return httpClient.put<ApiResponse<UpdateQuoteResponse>>(`${QUOTES_URL}/${payload.id}`, payload);
}