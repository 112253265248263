import { createSlice } from "@reduxjs/toolkit";
import { dashboardInitialState } from "./dashboard.initialState";

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: dashboardInitialState,
    reducers: {},
    extraReducers() {

    }
})

export const {} = dashboardSlice.actions;

export const { reducer: dashboardReducer } = dashboardSlice;