import { createSlice } from "@reduxjs/toolkit";
import { authenticationInitialState } from "./authentication.initialState";
import { fetchAllUsers, fetchLoginUser, setUserLogin} from "./authentication.actions";

const authenticationSlice = createSlice({
    name: "authentication",
    initialState: authenticationInitialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
            state.appUsers = action.payload;
        });
        builder.addCase(fetchAllUsers.pending, (state) => {
            state.appUsers = [];
        });
        builder.addCase(fetchAllUsers.rejected, (state) => {
            state.appUsers = [];
        });
        builder.addCase(setUserLogin.fulfilled, (state, action) => {
            state.tokenData = action.payload.data ?? null;
            
        });
        builder.addCase(setUserLogin.pending, (state) => {
            state.tokenData = null;
        });
        builder.addCase(setUserLogin.rejected, (state) => {
            state.tokenData = null;
        });
        builder.addCase(fetchLoginUser.fulfilled, (state, action) => {
            state.currentAppUser = action.payload;
        });
        builder.addCase(fetchLoginUser.pending, (state) => {
            state.currentAppUser = null;
        });
        builder.addCase(fetchLoginUser.rejected, (state) => {
            state.currentAppUser = null;
        });
    }
})

export const {} = authenticationSlice.actions;

export const { reducer: authenticationReducer } = authenticationSlice;