import { AxiosError, AxiosResponse } from 'axios';
import axiosInstance from './axiosInstance';
import { redirectUrlKey, userAuthUrl } from '../constants/appConstants';


axiosInstance.interceptors.request.use(
    (config) => 
    {
        const skipAuthUrls = ["/login", "/register"];

        // Check if the URL contains a path that should skip the Authorization header
        if (skipAuthUrls.some((url) => config.url?.includes(url))) {
            return config;
        }
        const token = localStorage.getItem('authToken');
        if (token) 
        {
            config.headers.Authorization = `Bearer ${atob(token)}`
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

axiosInstance.interceptors.response.use((response: AxiosResponse) => response, (error: AxiosError) => {
    console.log('axios error = ', error);
    if (error.code === 'ERR_NETWORK') {
        localStorage.setItem(redirectUrlKey, window.location.pathname);
        window.location.href = userAuthUrl;
    } else {
        if (error.response?.status === 401) {
            window.location.href = '/login';
        }
    }
    return Promise.reject(error);
})

export default axiosInstance;