// Base Url
export const BASE_URL = import.meta.env.VITE_SERVER_BASE_URL;
// export const BASE_URL = 'http://localhost:3001/api';
// export const BASE_URL = 'https://ssp-server-2f2fac10678c.herokuapp.com/api';

// Upload File
const UPLOAD_FILE_URL = `${BASE_URL}/uploadfile`

// Dashboard Url
const DASHBOARD_URL = `${BASE_URL}/dashboard`;

// Feedback Url
const FEEDBACK_URL = `${BASE_URL}/feedback`;

// Quotes Url
const QUOTES_URL = `${BASE_URL}/quotes`

// Login Url
const USER_URL = `${BASE_URL}/user`;


export {
  DASHBOARD_URL,
  FEEDBACK_URL,
  UPLOAD_FILE_URL,
  USER_URL,
  QUOTES_URL,
};
