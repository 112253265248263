import { Transition, Dialog, TransitionChild, DialogPanel, DialogTitle } from "@headlessui/react";
import { Fragment, useState } from "react";
import { BsExclamationTriangle } from "react-icons/bs";
import { ToastType } from "../constants/appConstants";

interface PromiseResolve {
    resolve: (value: boolean | PromiseLike<boolean>) => void;
}

interface ConfirmValues {
    title: string;
    message: string;
    closeButton: string;
    confirmButton: string;
    confirmType: ToastType;
}

const defaultConfirmValues: ConfirmValues = {
    title: "",
    message: "",
    closeButton: "Cancel",
    confirmButton: "Confirm",
    confirmType: ToastType.Success
}

const useConfirm = (): [() => JSX.Element | null, (title: string, message: string, closeButton?: string, confirmButton?: string, confirmType?: ToastType) => Promise<boolean>] => {
    const [promise, setPromise] = useState<PromiseResolve | undefined>(undefined);
    const [isOpen, setIsOpen] = useState(false);
    const [confirmValues, setConfirmValues] = useState<ConfirmValues>(defaultConfirmValues);
    const confirm = (title: string, message: string, closeButton: string = defaultConfirmValues.closeButton, confirmButton: string = defaultConfirmValues.confirmButton, confirmType: ToastType = defaultConfirmValues.confirmType) => {
        setConfirmValues({
            title,
            message,
            closeButton,
            confirmButton,
            confirmType
        });
        setIsOpen(true);
        return new Promise<boolean>((resolve) => {
            setPromise({ resolve });
        })
    };

    const handleClose = () => {
        setPromise(undefined);
        setIsOpen(false);
        setConfirmValues(defaultConfirmValues);
    }

    const handleConfirm = () => {
        promise?.resolve(true);
        handleClose();
    }

    const handleCancel = () => {
        promise?.resolve(false);
        handleClose();
    }


    const ConfirmationDialog = (): JSX.Element | null => {
        if (!isOpen) return null;
        return (
            <Transition show={isOpen} as={Fragment}>
                <Dialog as='div' className={`relative z-50`} onClose={handleClose}>
                    <TransitionChild as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in durationnnn-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
                        <div className='fixed inset-0 bg-black bg-opacity-25'></div>
                    </TransitionChild>
                    <div className='fixed inset-0 z-50 flex items-center justify-center p-4'>
                        <TransitionChild as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0 scale-95 translate-y-4' enterTo='opacity-100 scale-100 translate-y-0' leave='ease-in duration-200' leaveFrom='opacity-100 scale-100 translate-y-0' leaveTo='opacity-0 scale-95 translate-y-4'>
                            <DialogPanel className="max-w-lg w-full bg-white rounded-lg shadow-lg p-6">
                                <div className="flex items-center">

                                    <div className={`flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ${confirmValues.confirmType === ToastType.Error ? 'bg-red-100' : 'bg-green-100'} `}>
                                        <BsExclamationTriangle className={`h-6 w-6 ${confirmValues.confirmType === ToastType.Error ? 'text-red-600' : 'text-green-600'}`} aria-hidden="true" />
                                    </div>

                                    <div className="ml-4">
                                        <DialogTitle className="text-lg font-medium text-gray-900">
                                            {confirmValues.title}
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                {confirmValues.message}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 flex justify-end space-x-3">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                        onClick={handleCancel}
                                    >
                                        {confirmValues.closeButton === '' ? defaultConfirmValues.closeButton : confirmValues.closeButton}
                                    </button>
                                    <button
                                        type="button"
                                        className={`inline-flex justify-center rounded-md  px-4 py-2 text-sm font-medium text-white ${confirmValues.confirmType === ToastType.Error ? 'bg-red-600 hover:bg-red-500 focus:ring-red-500' : 'bg-green-600 hover:bg-green-500 focus:ring-green-500'}  focus:outline-none focus:ring-2 focus:ring-offset-2 `}
                                        onClick={handleConfirm}
                                    >
                                        {confirmValues.confirmButton === '' ? defaultConfirmValues.confirmButton : confirmValues.confirmButton}
                                    </button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </Dialog>
            </Transition>
        );
    }

    return [ConfirmationDialog, confirm];
}

export default useConfirm