import { useState } from "react";
import { Header, Sidebar } from "../../components";

interface CMSLayoutProps {
    children: React.ReactNode;
}

const CMSLayout = ({ children }: CMSLayoutProps) => {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    return (
        <div className="">
            {/* page wrapper start */}
            <div className="flex h-screen overflow-hidden">
                {/* sidebar start */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* sidebar end */}
                {/** content area start */}
                <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
                    {/** header start */}
                    <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    {/** header end */}
                    {/** main content start */}
                    <main>
                        <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
                            {children}
                        </div>
                    </main>
                    {/** main content end */}
                </div>
                {/** content area end */}
            </div>
            {/* page wrapper end */}
        </div>
    )
}

export default CMSLayout