import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { useForm } from "react-hook-form";
import createToast from "../../hooks/createToast";
import { ToastType } from "../../constants/appConstants";
import { useAuth } from "../../context/AppContext";
import { ErrorMessage } from "../../components";
import { LoginForm } from "ssp-contracts";

const Login = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<LoginForm>();
    const { loginWithCredentials } = useAuth();
    // const currentUser = useSelector((state: RootState) => state.authentication.currentAppUser);

    const navigate = useNavigate();
    const handleGoogleSignIn = () => {
        throw new Error("Function not implemented.")
    }

    const onSubmit = handleSubmit(async (data) => {
        const userData: LoginForm = {
            email: data.email,
            password: data.password,
            isRemembered: data.isRemembered
        }
        const success = await loginWithCredentials(userData);
        if (success) {
            createToast('Login', `Login Successfully`, ToastType.Success);
            navigate("/dashboard");
        } else {
            createToast('Invalid Credentials', 'email or password is invalid, please try again!!', ToastType.Error);
        }
    });

    return (
        <div className="max-w-md mx-auto mt-10">
            <div className="rounded-sm border border-stroke bg-white shadow-lg dark:border-strokedark dark:bg-boxdark">
                <div className="border-b border-stroke py-6 px-8 dark:border-strokedark">
                    <h3 className="text-2xl font-semibold text-black dark:text-white text-center">
                        Sign In
                    </h3>
                </div>
                <form onSubmit={onSubmit}>
                    <div className="p-8">
                        <div className="mb-6">
                            <label className="block text-black dark:text-white mb-2">
                                Email
                            </label>
                            <input
                                type="email"
                                placeholder="Enter your email address"
                                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                                {...register("email", { required: 'Email Address is Required' })}

                            />
                            <ErrorMessage name="email" errors={errors} />

                        </div>

                        <div className="mb-6">
                            <label className="block text-black dark:text-white mb-2">
                                Password
                            </label>
                            <input
                                type="password"
                                placeholder="Enter password"
                                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                                {...register("password", { required: 'Password is required' })}
                            />
                            <ErrorMessage name="password" errors={errors} />
                        </div>

                        <div className="flex items-center justify-between mb-6">
                            <div className="flex items-center gap-2">
                                <div className="flex h-6 items-center">
                                    <input
                                        id="candidates"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        {...register("isRemembered", { value: true })}
                                    />
                                </div>
                                <label htmlFor="formCheckbox" className="flex items-center cursor-pointer">
                                    <p className="text-sm">Remember Me</p>
                                </label>
                            </div>

                            <Link to="#" className="text-sm text-primary hover:underline">
                                Forgot password?
                            </Link>
                        </div>

                        <button className="w-full flex justify-center rounded bg-primary text-white py-3 px-5 font-medium hover:bg-opacity-90 transition">
                            Sign In
                        </button>

                        <div className="my-4 text-center text-gray-500 dark:text-gray-400">
                            OR
                        </div>

                        <button
                            type="button"
                            onClick={handleGoogleSignIn}
                            className="w-full flex justify-center items-center rounded bg-white border border-stroke py-3 px-5 font-medium text-black dark:bg-form-input dark:border-strokedark dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 transition"
                        >
                            <FcGoogle className="mr-3 text-2xl" />
                            Sign In with Google
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login