import { BrowserRouter } from 'react-router-dom';
import './App.css'
import { I18nextProvider } from 'react-i18next';
import AppRoutes from './routes/router';
import i18n from './services/i18n';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './context/AppContext';

function App() {

  return (
    <div className='font-body'>
      <BrowserRouter>
        {/* <ReactNotifications /> */}
        <Toaster />
        <I18nextProvider i18n={i18n}>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </I18nextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App
