import { jwtDecode, JwtPayload } from "jwt-decode";

export const isTokenExpired = (token: string): boolean => {
    try {
        const decodedToken = jwtDecode<JwtPayload>(token);
        const currentTime = Math.floor(Date.now() / 1000);
        return decodedToken.exp! < currentTime;
    } catch(error) {
        console.error("Failed to decode token", error);
        return true;
    }
}