import { Dialog, DialogBackdrop, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react"
import { Fragment, useEffect, useRef, useState } from "react";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import { HiPhoto } from "react-icons/hi2";
import { ToastType } from "../../../constants/appConstants";
import createToast from "../../../hooks/createToast";
import { fetchAllFeedback, getFeedbackDocument, updateFeedback, insertFeedback } from "../../../store";
import { useAppDispatch } from "../../../store/store";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FeedbackForm, FeedbackResponse, InsertFeedbackRequest, UpdateFeedbackRequest } from "ssp-contracts";
import { AppLoader, ErrorMessage } from "../../../components";
import { areObjectsEqualByKeys, getFullUrl } from "../../../utilities/commonLibFunc";


interface CruFeedbackProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    selectedFeedback: FeedbackResponse;
    isEditFeedback: boolean;
    setIsEditFeedback: (value: boolean) => void;
}
const CruFeedback = ({ open, setOpen, isEditFeedback, setIsEditFeedback, selectedFeedback }: CruFeedbackProps) => {
    const { control, handleSubmit, formState: { errors }, setValue, reset, getValues } = useFormContext();
    const [previewImage, setPreviewImage] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);  // New state for loader

    const dispatch = useAppDispatch();
    const inputRef = useRef(null);

    // const isNewAndPreviousDataSame = (data: FieldValues, previousData: Feedback) => {
    //     const fileName = previousData.imageUrl.split('/').pop() || previousData.imageUrl;
    //     if (data.title === previousData.title && data.description === previousData.description && data.uploadImage[0].name === fileName) {
    //         return true;
    //     }
    //     return false;
    // }

    const feedbackProperties: (keyof FeedbackForm)[] = [
        "title",
        "description",
        "uploadImage",
    ]

    const isNewAndPreviousDataSame = (data: FieldValues, previousData: FeedbackResponse) => {
        return areObjectsEqualByKeys(data, previousData, feedbackProperties);
    }


    const onSubmit = handleSubmit((data) => {
        setLoading(true);  // Show loader when submission starts
        if (isEditFeedback) {
            if (data.uploadImage && data.uploadImage instanceof FileList && data.uploadImage.length > 0) {
                const file = data.uploadImage[0];

                const fileName = selectedFeedback.images?.preview;

                const updateFeedbackData: UpdateFeedbackRequest = {
                    id: selectedFeedback.id,
                    title: data.title,
                    description: data.description,
                    oldFileName: fileName,
                    newUploadFile: file,
                    path: 'feedback'
                }

                if (!isNewAndPreviousDataSame(data, selectedFeedback)) {
                    dispatch(updateFeedback(updateFeedbackData)).then((response) => {
                        if (response.meta.requestStatus === 'fulfilled') {
                            dispatch(fetchAllFeedback());
                            createToast('Feedback Update', 'Feedback updated successfully', ToastType.Success);
                            onClose();

                        } else {
                            createToast('File Upload Failed!', 'File Upload Failed, Please Check the logs', ToastType.Error);
                            return;
                        }

                    }).catch((err) => {
                        createToast('Some Error Occurred', 'Some Error Occurred, Please Check the logs', ToastType.Error);
                        console.log(err);
                        return;
                    }).finally(() => {
                        setLoading(false);

                    });
                } else {
                    setLoading(false);
                    onClose();
                }
            };
        } else {
            if (data.uploadImage && data.uploadImage instanceof FileList && data.uploadImage.length > 0) {
                const file = data.uploadImage[0];

                const newFeedback: InsertFeedbackRequest = {
                    title: data.title,
                    description: data.description,
                    uploadFile: file,
                    path: 'feedback'
                }

                dispatch(insertFeedback(newFeedback)).then((response) => {
                    if (response.meta.requestStatus === 'fulfilled') {
                        dispatch(fetchAllFeedback());
                        createToast('Feedback Saved', 'Feedback submitted successfully', ToastType.Success);
                        onClose();

                    } else {
                        createToast('File Upload Failed!', 'File Upload Failed, Please Check the logs', ToastType.Error);
                        return;
                    }

                }).catch((err) => {
                    createToast('Some Error Occurred', 'Some Error Occurred, Please Check the logs', ToastType.Error);
                    console.log(err);
                    return;
                }).finally(() => {
                    setLoading(false);
                });
            };
        }

    });

    const handleImageChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {

            if (file.size > 10 * 1024 * 1024) {
                createToast('File too large', 'Please upload a file smaller than 10MB', ToastType.Error);
                return;
            }
            if (!['image/png', 'image/jpeg', 'image/gif'].includes(file.type)) {
                createToast('Invalid file type', 'Please upload a PNG, JPG, or GIF', ToastType.Error);
                return;
            }

            const imageUrl = URL.createObjectURL(file);
            setPreviewImage(imageUrl);
        } else {
            setPreviewImage('');
        }
    };

    const onClose = () => {
        if (!loading) {
            setIsEditFeedback(false);
            setPreviewImage(''); // Reset preview image separately
            reset();
            setOpen(false);
        }
    }

    useEffect(() => {
        if (isEditFeedback) {
            // Set form values when editing or viewing
            setValue('title', selectedFeedback.title || '');
            setValue('description', selectedFeedback.description || '');

            const fileName = selectedFeedback.images?.preview;
            if (fileName) {
                setPreviewImage(getFullUrl(fileName));
                console.log('before set', getValues('uploadImage'));
                // Fetch the image and create a File object
                dispatch(getFeedbackDocument(fileName))
                    .then((blob) => {
                        const file = new File([blob.payload as Blob], fileName, { type: blob.type });

                        const dataTransfer = new DataTransfer();
                        dataTransfer.items.add(file);

                        setValue('uploadImage', dataTransfer.files); // Set the file to the form value
                        console.log('after set', getValues('uploadImage'));
                    })
                    .catch(err => console.error('Error fetching image:', err));
            }
        } else {
            // Reset form for new quote
            reset({
                title: '',
                description: '',
            });
            setPreviewImage('');
        }
    }, [selectedFeedback, isEditFeedback, setValue, reset]);

    return (
        <Transition show={open} as={Fragment}>
            <Dialog as="div" onClose={() => { }} static className="relative z-999">
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                    />
                </TransitionChild>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        {/* Overlay Loader */}
                        {loading && (
                            <div className="absolute inset-0 z-20 flex items-center justify-center bg-gray-900 bg-opacity-50">
                                <AppLoader text="Uploading... Please wait." /> {/* Loader with Overlay */}
                            </div>
                        )}
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel
                                transition
                                className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-4xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                            >
                                <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                                    <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark flex justify-between ">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            {isEditFeedback ? 'Update Feedback' : 'Add Feedback'}
                                        </DialogTitle>
                                        <IoMdCloseCircleOutline onClick={() => setOpen(false)} className="text-2xl cursor-pointer text-slate-500 hover:text-slate-700" />
                                    </div>
                                    <form onSubmit={(e) => { e.preventDefault(); onSubmit(e); }}>
                                        <div className="p-6.5">
                                            <div className="mb-4.5 flex flex-col gap-6">
                                                <div className="w-full">
                                                    <label className="mb-2.5 block text-black dark:text-white">
                                                        Title
                                                    </label>
                                                    <Controller
                                                        name='title'
                                                        control={control}
                                                        defaultValue={''}
                                                        rules={{
                                                            required: 'Title is Required',
                                                        }}
                                                        render={({ field }) => <input
                                                            type="text"
                                                            {...field}
                                                            placeholder="Enter Title"
                                                            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                                                        />}
                                                    />
                                                    <ErrorMessage errors={errors} name={'title'} />

                                                </div>
                                                <div className="w-full">
                                                    <label className="mb-2.5 block text-black dark:text-white">
                                                        Description
                                                    </label>
                                                    <Controller
                                                        name='description'
                                                        control={control}
                                                        defaultValue={''}
                                                        rules={{
                                                            required: 'Description is Required',
                                                        }}
                                                        render={({ field }) => <textarea
                                                            rows={6}
                                                            {...field}
                                                            placeholder="Enter Description"
                                                            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                                                        ></textarea>}
                                                    />
                                                    <ErrorMessage errors={errors} name={'description'} />

                                                </div>
                                                <div className="w-full">
                                                    <label htmlFor="feedback-photo" className="mb-2.5 block text-black dark:text-white">
                                                        Feedback Image
                                                    </label>
                                                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                                        <div className="text-center">
                                                            {previewImage === '' ? (
                                                                <HiPhoto aria-hidden="true" className="mx-auto h-12 w-12 text-gray-300" />
                                                            ) : (
                                                                <img src={previewImage} alt="Uploaded Preview" className="mx-auto h-32 w-32 object-cover" />
                                                            )}
                                                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                                <label
                                                                    htmlFor="file-upload"
                                                                    className="relative cursor-pointer rounded-md bg-transparent font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                                >
                                                                    <span>Upload a file</span>
                                                                    <Controller
                                                                        name='uploadImage'
                                                                        control={control}
                                                                        rules={{
                                                                            required: 'Upload Image is Required',
                                                                        }}
                                                                        render={({ field }) =>
                                                                            <input
                                                                                ref={inputRef}
                                                                                id="file-upload"
                                                                                type="file"
                                                                                className="sr-only"
                                                                                onChange={(event) => {
                                                                                    const files = event.target.files;
                                                                                    console.log('files = ', files);
                                                                                    if (files && files.length > 0) {
                                                                                        field.onChange(files); // Pass the entire FileList to the form
                                                                                    } else {
                                                                                        field.onChange(null); // Handle the case where no files are selected
                                                                                    }
                                                                                    handleImageChange(event);
                                                                                }} />}
                                                                    />

                                                                </label>
                                                                <p className="pl-1">or drag and drop</p>
                                                            </div>
                                                            <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage errors={errors} name={'uploadImage'} />
                                                </div>
                                            </div>
                                            {/* <button className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90">
                                        Upload Feedback
                                    </button> */}
                                        </div>
                                        <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                            <button
                                                type="submit"
                                                disabled={loading}
                                                className="inline-flex w-full justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                                            >
                                                {loading ? 'Saving...' : isEditFeedback ? 'Update Feedback' : 'Add Feedback'}
                                            </button>
                                            <button
                                                type="button"
                                                data-autofocus
                                                onClick={onClose}
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </div>


                            </DialogPanel>
                        </TransitionChild>

                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default CruFeedback