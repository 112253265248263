import { useSelector } from "react-redux";
import { useState } from "react";
import { RootState, useAppDispatch } from "../../store/store";
import CruFeedback from "./CruFeedback";
import { FormProvider, useForm } from "react-hook-form";
import { deleteFeedback, fetchAllFeedback } from "../../store";
import { GrFormView } from "react-icons/gr";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin5Line } from "react-icons/ri";
import { DeleteFeedbackRequest, FeedbackForm, FeedbackResponse } from "ssp-contracts";
import useConfirm from "../../hooks/useConfirm";
import { ToastType } from "../../constants/appConstants";
import createToast from "../../hooks/createToast";
import FeedbackPreview from "./FeedbackPreview";
import { getFullUrl } from "../../utilities/commonLibFunc";

const Feedbacks = () => {

    const defaultValuesForFeedbackForm: FeedbackForm = {
        title: "",
        description: "",
        uploadImage: null
    }
    const methods = useForm<FeedbackForm>({
        defaultValues: defaultValuesForFeedbackForm
    });
    const dispatch = useAppDispatch();
    const [ConfirmationDialog, confirm] = useConfirm();

    const feedbacks = useSelector((state: RootState) => state.feedback.feedbackData);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const feedbacksPerPage = 5;

    const [showModal, setShowModal] = useState(false);

    const [selectedFeedback, setSelectedFeedback] = useState<FeedbackResponse>({
        id: '',
        title: '',
        description: '',
        images: {
            thumbnail: "",
            preview: "",
            full: ""
        }
    });



    const [showPreview, setShowPreview] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    const filteredFeedbacks = feedbacks?.filter((feedback) =>
        feedback?.title?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        feedback?.description?.toLowerCase().includes(searchQuery?.toLowerCase())
    );

    // Pagination logic
    const indexOfLastFeedback = currentPage * feedbacksPerPage;
    const indexOfFirstFeedback = indexOfLastFeedback - feedbacksPerPage;
    const currentFeedbacks = filteredFeedbacks.slice(indexOfFirstFeedback, indexOfLastFeedback);

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const handleEditFeedback = async (feedback: FeedbackResponse) => {
        const isConfirm = await confirm('Edit Quote', 'Are you sure want to Edit?', 'No', 'Yes');
        if (isConfirm) {
            setShowModal(true);
            setSelectedFeedback(feedback);
            setIsEditMode(true);
        } else {
            setIsEditMode(false);
        }
    }

    const handleViewFeedback = (feedback: FeedbackResponse) => {
        setShowPreview(true);
        setSelectedFeedback(feedback);
    }

    const handleDeleteFeedback = async (feedback: FeedbackResponse) => {
        const isConfirm = await confirm('Delete Feedback', 'Are you sure want to Delete?', 'Cancel', 'Confirm', ToastType.Error);
        if (isConfirm) {
            const fileName = feedback.images.thumbnail;
            const deleteFeedbackData: DeleteFeedbackRequest = {
                id: feedback.id,
                fileName: `${fileName}`
            }
            dispatch(deleteFeedback(deleteFeedbackData)).then((feedbackResponse) => {
                if (feedbackResponse.meta.requestStatus === 'fulfilled') {
                    dispatch(fetchAllFeedback());
                    createToast('Feedback Deleted', 'Feedback deleted successfully', ToastType.Success);
                } else {
                    createToast('Feedback Deletion Error', 'Some Error Occured While deleting Feedback ', ToastType.Error);
                }
            })
        }
    }

    return (
        <FormProvider {...methods}>
            <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
                {/* <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                    Feedbacks
                </h4> */}
                <div className="flex justify-between items-center mb-2 w-full">
                    <input
                        type="text"
                        placeholder="Search by title or description..."
                        className="mb-4 p-2 border rounded"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button className="px-2 py-2 border rounded bg-primary text-white" onClick={() => setShowModal(true)}>Add Feedback</button>
                </div>
                <div className="flex flex-col">
                    <div className="grid grid-cols-3 rounded-sm bg-gray-2 dark:bg-meta-4 sm:grid-cols-4">
                        <div className="p-2.5 xl:p-5">
                            <h5 className="text-sm font-medium uppercase xsm:text-base">
                                Upload Image
                            </h5>
                        </div>
                        <div className="p-2.5 text-left xl:p-5">
                            <h5 className="text-sm font-medium uppercase xsm:text-base">
                                Title
                            </h5>
                        </div>
                        <div className="p-2.5 text-left xl:p-5">
                            <h5 className="text-sm font-medium uppercase xsm:text-base">
                                Description
                            </h5>
                        </div>
                        <div className="hidden p-2.5 text-center sm:block xl:p-5">
                            <h5 className="text-sm font-medium uppercase xsm:text-base">
                                Action
                            </h5>
                        </div>
                    </div>
                    {currentFeedbacks.length > 0 ? (
                        currentFeedbacks.map((feedback, key) => (
                            <div
                                className={`grid grid-cols-3 sm:grid-cols-4 h-1/4 ${key === feedbacks.length - 1
                                    ? ''
                                    : 'border-b border-stroke dark:border-strokedark'
                                    }`}
                                key={key}
                            >
                                <div className="flex items-center gap-3 p-2.5 xl:p-5">
                                    <div className="flex-shrink-0">
                                        <img src={getFullUrl(feedback.images?.thumbnail)} alt="Brand" className="h-30 " />
                                    </div>
                                    {/* <p className="hidden text-black dark:text-white sm:block">
                                        {feedback._id}
                                    </p> */}
                                </div>

                                <div className="flex items-center justify-left p-2.5 xl:p-5">
                                    <p className="text-primary font-semibold dark:text-white">{feedback.title}</p>
                                </div>

                                <div className="flex items-center justify-left p-2.5 xl:p-5 relative">
                                    <div className="max-h-1/4 overflow-hidden relative">
                                        <p className="text-black dark:text-white line-clamp-3">{feedback.description}</p>
                                    </div>
                                    {feedback.description.length > 100 && (
                                        <button
                                            className="absolute bottom-0 right-5 p-2.5 xl:p-5"
                                            onClick={() => handleViewFeedback(feedback)}>view more</button>
                                    )}
                                </div>

                                <div className="hidden items-center justify-center p-2.5 sm:flex xl:p-5">
                                    <div className="text-black dark:text-white flex justify-center items-center grid-4">
                                        <GrFormView className="cursor-pointer text-2xl mr-1" onClick={() => handleViewFeedback(feedback)} />
                                        <TbEdit className="cursor-pointer text-2xl mr-1" onClick={() => handleEditFeedback(feedback)} />
                                        <RiDeleteBin5Line className="cursor-pointer text-2xl" onClick={() => handleDeleteFeedback(feedback)} />
                                    </div>
                                </div>
                            </div>
                        ))

                    ) : (
                        <div className="flex justify-center items-center py-4">
                            <p className="text-3xl font-bold text-meta-3">No feedbacks yet
                            </p>
                        </div>
                    )}
                </div>
                <div className="flex justify-center mt-4">
                    {Array.from({ length: Math.ceil(filteredFeedbacks.length / feedbacksPerPage) }, (_, i) => (
                        <button
                            key={i}
                            className={`mx-1 py-2 px-3 border rounded ${currentPage === i + 1 ? 'bg-primary text-white' : ''}`}
                            onClick={() => paginate(i + 1)}
                        >
                            {i + 1}
                        </button>
                    ))}
                </div>
            </div>
            <CruFeedback open={showModal} setOpen={setShowModal} selectedFeedback={selectedFeedback} isEditFeedback={isEditMode} setIsEditFeedback={setIsEditMode} />
            <FeedbackPreview open={showPreview} setOpen={setShowPreview} selectedFeedback={selectedFeedback} />
            <ConfirmationDialog />
        </FormProvider>
    )
}

export default Feedbacks