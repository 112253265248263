import { Navigate, Route, Routes } from "react-router-dom"
import { Dashboard, NotFound, Quotes, ViewFeedbacks } from "../pages"
import CMSLayout from "../layout/CMSLayout"
import { Breadcrumb, PageTitle } from "../components"
import Login from "../pages/Login"
import PrivateRoute from "./PrivateRoute"


const AppRoutes = () => {
    return (

        <Routes>
            {/** public routes */}
            <Route path="/login" element={<Login />} />

            {/** private routes */}
            <Route element={<PrivateRoute />}>
                <Route path="/dashboard" element={
                    <CMSLayout>
                        <>
                            <PageTitle title="Dashboard" />
                            <Dashboard />
                        </>
                    </CMSLayout>
                } />
                <Route path="/feedback" element={
                    <CMSLayout>
                        <>
                            <PageTitle title="Feedbacks" />
                            <Breadcrumb pageName="Feedbacks" />
                            {/* <Alert title="testing" message="testing message" type={ToastType.Error} /> */}
                            <ViewFeedbacks />
                        </>
                    </CMSLayout>
                }
                />
                <Route path="/quotes" element={
                    <CMSLayout>
                        <>
                            <PageTitle title="Quotes" />
                            <Breadcrumb pageName="Quotes" />
                            {/* <Alert title="testing" message="testing message" type={ToastType.Error} /> */}
                            <Quotes />
                        </>
                    </CMSLayout>
                }
                />
                <Route path="/settings/menu" element={
                    <CMSLayout>
                        <>
                            <PageTitle title="Menus" />
                            <Breadcrumb pageName="Menus" />
                            {/* <Alert title="testing" message="testing message" type={ToastType.Error} /> */}
                            {/* <Menus /> */}
                        </>
                    </CMSLayout>
                }
                />
            </Route>
            <Route index element={<Navigate to={`/dashboard`} />} />
            <Route path="*" element={
                <>
                    <PageTitle title="Page Not Found" />
                    <NotFound />
                </>
            } />
        </Routes>


    )
}

export default AppRoutes