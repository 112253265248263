import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { QuoteResponse } from "ssp-contracts";

interface QuotePreviewProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    selectedQuote: QuoteResponse;
}
const QuotePreview = ({ open, setOpen, selectedQuote }: QuotePreviewProps) => {

    const onClose = () => {
        setOpen(false);
    }
    return (
        <Dialog open={open} onClose={onClose} className="relative z-999">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-4xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                            <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark flex justify-between ">
                                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                    {'Quote'}
                                </DialogTitle>
                                <IoMdCloseCircleOutline onClick={onClose} className="text-2xl cursor-pointer text-slate-500 hover:text-slate-700" />
                            </div>

                            <div className="p-6.5">
                                <div className="mb-4.5 flex flex-col gap-6 py-10">
                                    {selectedQuote.description}
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={onClose}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    {'Close'}
                                </button>
                            </div>
                        </div>


                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

export default QuotePreview