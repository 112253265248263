import { createAsyncThunk } from "@reduxjs/toolkit";
import * as quoteApi from './quote.api';
import { ApiResponse, DeleteQuoteRequest, InsertQuoteRequest, InsertQuoteResponse, QuoteResponse, UpdateQuoteRequest, UpdateQuoteResponse } from "ssp-contracts";
import { AxiosError } from "axios";


export const fetchAllQuotes = createAsyncThunk<ApiResponse<QuoteResponse[]>>('quote/fetchAllQuotes', async (_, thunkAPI) => {
    try {
        const response = await quoteApi.getAllQuotes();
        if (response.data.success) {
            return response.data;
        } else {
            return thunkAPI.rejectWithValue(response.data.error || 'Faile to fetch Quotes');
        }
    } catch (error) {
        return thunkAPI.rejectWithValue((error as AxiosError).message || 'Failed to fetch Quotes');
    }
});

export const createQuote = createAsyncThunk<ApiResponse<InsertQuoteResponse>, InsertQuoteRequest>('quote/createQuote', async (payload: InsertQuoteRequest, thunkAPI) => {
    try {
        const response = await quoteApi.insertQuote(payload);
        if (response.data.success) {
            return response.data;
        } else {
            return thunkAPI.rejectWithValue(response.data.error || 'Failed to create Quote');
        }

    } catch (error) {
        return thunkAPI.rejectWithValue((error as AxiosError).message || 'Failed to create Quote');

    }
})

export const deleteQuote = createAsyncThunk<ApiResponse<void>, DeleteQuoteRequest>('quote/deleteQuote', async (payload: DeleteQuoteRequest, thunkAPI) => {
    try {
        const response = await quoteApi.removeQuote(payload);
        if (response.status === 200 && response.data.success) {
            return response.data;
        } else {
            return thunkAPI.rejectWithValue(response.data.error || 'Failed to delete Quote');
        }

    } catch (error) {
        return thunkAPI.rejectWithValue((error as AxiosError).message || 'Failed to delete Quote');

    }
})

export const updateQuote = createAsyncThunk<ApiResponse<UpdateQuoteResponse>, UpdateQuoteRequest>('quote/updateQuote', async (payload: UpdateQuoteRequest, thunkAPI) => {
    try {
        const response = await quoteApi.modifyQuote(payload);
        if (response.data.success) {
            return response.data;
        } else {
            return thunkAPI.rejectWithValue(response.data.error || 'Failed to update Quote');
        }

    } catch (error) {
        return thunkAPI.rejectWithValue((error as AxiosError).message || 'Failed to update Quote');

    }
})